import React, { useEffect, useState } from "react";
import "./clients.scss"
import AddClient from "../../Modals/ClientModals/AddClient/addClientModal";
import DeleteClient from "../../Modals/ClientModals/DeleteClient/deleteClientModal";
import EditClient from "../../Modals/ClientModals/EditClientModal/editClientModal";
import QrCode from "../../Modals/ClientModals/QrCodeModal/qrCodeModal";
import axios from "../../axios";
const Clients = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isQrOpen, setIsQrOpen] = useState(false)
    const [users, setUsers] = useState([])
    const [dataToModal, setDataToModal] = useState()


    const getUsers = () => {
        axios.get('/users/').then(
            data => {
                setUsers(data?.data?.filter(el => el?.role == 3))
            }
        ).catch(err => {
            console.log(err)
        })

    }


    function handleModalClose() {
        setIsOpen(false)
        setIsEditOpen(false)
        setIsQrOpen(false)
        setIsDeleteOpen(false)
    }
    function handelModalEdit(data) {
        setIsEditOpen(true)
        setDataToModal(data)
    }
    function handelModalDelete(data) {
        setIsDeleteOpen(true)
        setDataToModal(data)
    }
    function handelModalQr(data) {
        setIsQrOpen(true)
    }
    useEffect(() => {
        getUsers()
    }, [])
    return (
        <>
            {isOpen == true ?
                <AddClient
                    handleModalClose={handleModalClose}
                    getUsers={getUsers}
                />
                :
                ""
            }
            {isEditOpen == true ?
                <EditClient
                    handleModalClose={handleModalClose}
                    dataToModal={dataToModal}
                    getUsers={getUsers}
                />
                :
                ""
            }
            {isDeleteOpen == true ?
                <DeleteClient
                    handleModalClose={handleModalClose}
                    id={dataToModal}
                    getUsers={getUsers}
                />
                :
                ""
            }
            {isQrOpen == true ?
                <QrCode
                    handleModalClose={handleModalClose}
                />
                :
                ""
            }
            <div className="container mt-5">
                <div className="row">
                    <div className="col-6">
                        <h1>Klientët</h1>
                    </div>
                    <div className="col-6 d-flex mb-1 flex-row-reverse">
                        {/* <div className="add-client-btn"> */}
                    <button className="btn btn-sm btn-success" onClick={() => setIsOpen(true)}><strong>Shto Klient</strong></button>
                    <input className="form-control col-sm-4 mt-2 mr-2" type="text" placeholder="Search" />
                    </div>
                    {/* </div> */}
                </div>
                <div style={{ overflowX: "auto" }}>
                    <table class="table table-striped table-dark border">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Credit</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((el, index) => {
                                return (
                                    <>
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{el?.userName}</td>
                                            <td>{el?.credit}</td>
                                            <td>
                                                <div className="d-flex gap-3">
                                                    <i class="fa-regular fa-pen-to-square" role="button" onClick={() => handelModalEdit(el)}></i>
                                                    <i class="fa-solid fa-trash" role="button" onClick={() => handelModalDelete(el._id)}></i>
                                                    {/* <i class="fa-solid fa-qrcode" role="button" onClick={() => handelModalQr()}></i> */}
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}
export default Clients
