import React, { useEffect, useState } from "react";
import AddStaff from "../../Modals/StaffModals/AddStaff/addStaffModal";
import EditStaff from "../../Modals/StaffModals/EditStaff/editStaffModal"
import DeleteStaff from "../../Modals/StaffModals/DeleteStaff/deleteStaff";
import axios from "../../axios";
import Payment from "../../Modals/StaffModals/StaffPayment/paymentModal";
const Staff = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isPayment, setIsPayment] = useState(false)
    const [users, setUsers] = useState([])
    const [dataToModal, setDataToModal] = useState()

    const getUsers = () => {
        axios.get('/users/').then(
            data => {
                setUsers(data?.data?.filter(el => el?.role == 2))
            }
        ).catch(err => {
            console.log(err)
        })

    }

    function handleModalClose() {
        setIsOpen(false)
        setIsEditOpen(false)
        setIsDeleteOpen(false)
        setIsPayment(false)
    }
    function handelModalEdit(data) {
        setIsEditOpen(true)
        setDataToModal(data)
    }
    function handelModalDelete(data) {
        setIsDeleteOpen(true)
        setDataToModal(data)
    }
    function handleModalPayment(data) {
        setIsPayment(true)
        setDataToModal(data)
    }
    useEffect(() => {
        getUsers()
    }, [])
    return (
        <div className="container mt-5">
            {isOpen == true ?
                <AddStaff
                    handleModalClose={handleModalClose}
                    getUsers={getUsers}
                />
                :
                ""
            }
            {isEditOpen == true ?
                <EditStaff
                    handleModalClose={handleModalClose}
                    getUsers={getUsers}
                    data={dataToModal}
                />
                :
                ""
            }
            {isDeleteOpen == true ?
                <DeleteStaff
                    handleModalClose={handleModalClose}
                    id={dataToModal}
                    getUsers={getUsers}
                />
                :
                ""
            }
            {isPayment == true ?
                <Payment
                    handleModalClose={handleModalClose}
                    data={dataToModal}
                    getUsers={getUsers}
                />
                :
                ""
            }
            <div className="row">
                <div className="col-6">
                    <h1>Stafi</h1>
                </div>
                <div className="col-6 d-flex mb-1 flex-row-reverse">
                    {/* <div className="add-client-btn"> */}
                    <button className="btn btn-sm btn-success" onClick={() => setIsOpen(true)}><strong>Shto Staff</strong></button>
                    <input className="form-control col-sm-4 mt-2 mr-2" type="text" placeholder="Search" />
                </div>
            </div>
            <div style={{ overflowX: "auto", backgroundColor: 'white' }}>
                <table className="table table-striped table-dark border" style={{ overflowX: "auto" }}>
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Full name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Credit</th>
                            <th scope="col">Roli</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users?.map((el, index) => {
                            return (
                                <>
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>{el?.userName}</td>
                                        <td>{el?.email}</td>
                                        <td>{el?.credit}</td>
                                        <td>{el?.role}</td>
                                        <td>
                                            <div className="d-flex gap-3">
                                                <i className="fa-regular fa-pen-to-square" role="button" onClick={() => handelModalEdit(el)}></i>
                                                <i className="fa-solid fa-trash" role="button" onClick={() => handelModalDelete(el?._id)}></i>
                                                {el?.role == 2 ?
                                                    <i class="fa-solid fa-euro-sign" onClick={() => handleModalPayment(el)} role="button" />
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Staff
