import React, { useEffect, useState } from "react";
import AddService from "../../Modals/ServicesModal/AddServiceModal/addServiceModal";
import EditService from "../../Modals/ServicesModal/EditServiceModal/editServiceModal";
import DeleteService from "../../Modals/ServicesModal/DeleteServiceModal/deleteServiceModal";
import axios from "../../axios";
const Services = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [services, setServices] = useState([])
    const [dataToModal, setDataToModal] = useState()

    const getServices = (e) => {
        axios.get('/services/').then(
            data => {
                setServices(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }

    function handleModalClose() {
        setIsOpen(false)
        setIsEditOpen(false)
        setIsDeleteOpen(false)
    }
    function handelModalEdit(data) {
        setIsEditOpen(true)
        setDataToModal(data)
    }
    function handelModalDelete(data) {
        setIsDeleteOpen(true)
        setDataToModal(data)
    }
    useEffect(() => {
        getServices()
    }, [])
    return (
        <>
            {isOpen == true ?
                <AddService
                    handleModalClose={handleModalClose}
                    getServices={getServices}
                />
                :
                ""
            }
            {isEditOpen == true ?
                <EditService
                    handleModalClose={handleModalClose}
                    data={dataToModal}
                    getServices={getServices}
                />
                :
                ""
            }
            {isDeleteOpen == true ?
                <DeleteService
                    handleModalClose={handleModalClose}
                    getServices={getServices}
                    id={dataToModal}
                />
                :
                ""
            }
            <div className="container mt-5">
                <div className="row">
                    <div className="col-6">
                        <h1>Sherbimet</h1>
                    </div>
                    <div className="col-6 d-flex mb-1 flex-row-reverse">
                        {/* <div className="add-client-btn"> */}
                        <button className="btn btn-sm btn-success" onClick={() => setIsOpen(true)}><strong>Shto Sherbim</strong></button>
                        <input className="form-control col-sm-4 mt-2 mr-2" type="text" placeholder="Search" />
                    </div>
                </div>
                <div style={{ overflowX: "auto" }}>
                    <table className="table table-striped table-dark border">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Emri i shërbimit</th>
                                <th scope="col">Cmimi (Piket)</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {services.map((el, index) => {
                                return (
                                    <>
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{el?.name}</td>
                                            <td>{el?.cost}</td>
                                            <td>
                                                <div className="d-flex gap-3">
                                                    <i className="fa-regular fa-pen-to-square" role="button" onClick={() => handelModalEdit(el)}></i>
                                                    <i className="fa-solid fa-trash" role="button" onClick={() => handelModalDelete(el._id)}></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default Services