import React, { useEffect, useState } from "react";
import AddSubscription from "../../Modals/SubscriptionModals/AddSubscription/addSubscriptionModal";
import EditSubscription from "../../Modals/SubscriptionModals/EditSubscription/editSubscriptionModal";
import DeleteSubscription from "../../Modals/SubscriptionModals/DeleteSubscription/deleteSubscriptionModal";
import axios from "../../axios";

const Subs = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [isEditOpen, setIsEditOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [subData, setSubData] = useState([])
    const [dataToModal, setDataToModal] = useState()
    const getSubscriptions = () => {
        axios.get("/subscriptions/").then(
            data => {
                setSubData(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }

    function handleModalClose() {
        setIsOpen(false)
        setIsEditOpen(false)
        setIsDeleteOpen(false)
    }
    function handelModalDelete(data) {
        setIsDeleteOpen(true)
        setDataToModal(data)
    }
    function handelModalEdit(data) {
        setIsEditOpen(true)
        setDataToModal(data)

    }
    useEffect(() => {
        getSubscriptions()
    }, [])
    return (
        <div className="container mt-5">
            {isOpen == true ?
                <AddSubscription
                    handleModalClose={handleModalClose}
                    getSubscriptions={getSubscriptions}
                />
                :
                ""
            }
            {isEditOpen == true ?
                <EditSubscription
                    handleModalClose={handleModalClose}
                    getSubscriptions={getSubscriptions}
                    data={dataToModal}
                />
                :
                ""
            }
            {isDeleteOpen == true ?
                <DeleteSubscription
                    handleModalClose={handleModalClose}
                    getSub={getSubscriptions}
                    id={dataToModal}
                />
                :
                ""
            }
            <div className="row">
                <div className="col-6">
                    <h1>Kartelat</h1>
                </div>
                <div className="col-6 d-flex mb-1 flex-row-reverse">
                    {/* <div className="add-client-btn"> */}
                    <button className="btn btn-sm btn-success" onClick={() => setIsOpen(true)}><strong>Shto Kartel</strong></button>
                    <input className="form-control col-sm-4 mt-2 mr-2" type="text" placeholder="Search" />
                </div>
            </div>
            <div className="add-client-btn">
            </div>
            <div style={{ overflowX: "auto" }}>
                <table className="table table-striped table-dark border">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Emri</th>
                            <th scope="col">Permbajtja e Pikëve</th>
                            <th scope="col">Cmimi (euro)</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subData?.map((el, index) => {
                            return (
                                <>
                                    <tr>
                                        <th scope="row">{index + 1}</th>
                                        <td>{el?.Name}</td>
                                        <td>{el?.Credit}</td>
                                        <td>{el?.Price}</td>
                                        <td>
                                            <div className="d-flex gap-3">
                                                <i className="fa-regular fa-pen-to-square" role="button" onClick={() => handelModalEdit(el)}></i>
                                                <i className="fa-solid fa-trash" role="button" onClick={() => handelModalDelete(el?._id)}></i>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default Subs