import React, { useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setLoggedIn, setUserToken, setUserId } from "../../redux/Functions/actions";
import "../header/header.scss"
import $ from 'jquery';
import Logo from "../../assets/images/sidebarlogo.webp"
const Header = (props) => {
    const location = useLocation()
    useEffect(() => {
        // Function to set default class values to "active"
        function setDefaultActive() {
            $('.sidebar').addClass('active');
            $('.overlay').addClass('active');
        }
    
        // Use useEffect for DOM-related actions
        $('.dismiss, .overlay').on('click', function () {
            $('.sidebar').removeClass('active');
            $('.overlay').removeClass('active');
        });
    
        $('.open-menu').on('click', function (e) {
            e.preventDefault();
            $('.sidebar').addClass('active');
            $('.overlay').addClass('active');
            // close opened sub-menus
            $('.collapse.show').toggleClass('show');
            $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        });
    
        // Set default class values when the component first loads
        setDefaultActive();
    
        // Cleanup the event handlers when the component unmounts
        return () => {
            $('.dismiss, .overlay, .open-menu').off('click');
        };
    }, []);
    

    const handleLogout = () => {
        props?.setLoggedIn(false)
        props?.setUserToken("")
        props?.setUserId("")
        window.localStorage.setItem('token', "")
        window.location.href = "/"
    }

    return (
        <>
        <nav class="navbar">
       </nav>
            <div class="wrapper">
                <nav class="sidebar">
                    <div class="dismiss">
                        <i class="fas fa-arrow-left"></i>
                    </div>
                    <div class="logo">
                        <img src={Logo} className="sidebarlogo" />
                    </div>
                    <ul class="list-unstyled menu-elements">
                        <li class={location.pathname == "/" ? "active" : ""}>
                            <Link to={"/"} class="scroll-link"><i class="fas fa-home"></i> Faqja Kryesore</Link>
                        </li>
                        <li class={location.pathname == "/client" ? "active" : ""}>
                            {props?.role == 2 ?
                                ""
                                :
                                <>
                                    <Link class="scroll-link" to={"/client"}><i class="fas fa-user"></i>Klientet</Link>
                                </>
                            }
                        </li>
                        <li class={location.pathname == "/staff" ? "active" : ""}>
                            {props?.role == 2 ?
                                ""
                                :
                                <>
                                    <Link class="scroll-link" to={"/staff"}><i class="fas fa-users"></i>Stafi</Link>
                                </>
                            }
                        </li>
                        <li class={location.pathname == "/subscrition" ? "active" : ""}>
                            {props?.role == 2 ?
                                ""
                                :
                                <>
                                    <Link class="scroll-link" to={"/subscrition"}><i class="fas fa-credit-card"></i> Kartelat</Link>
                                </>
                            }
                        </li>
                        <li class={location.pathname == "/services" ? "active" : ""}>
                            {props?.role == 2 ?
                                ""
                                :
                                <>
                                    <Link class="scroll-link" to={"/services"}><i class="fas fa-gears"></i>Sherbimet</Link>
                                </>
                            }
                        </li>
                        <li class={location.pathname == "/profile" ? "active" : ""}>
                                    <Link class="scroll-link" to={"/profile"}><i class="fas fa-user"></i>Profili</Link>
                        </li>
                        {/* <li>
             <a href="#otherSections" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle" role="button" aria-controls="otherSections">
                 <i class="fas fa-sync"></i>Other sections
             </a>
             <ul class="collapse list-unstyled" id="otherSections">
                 <li>
                     <a class="scroll-link" href="#section-3">Our projects</a>
                 </li>
                 <li>
                     <a class="scroll-link" href="#section-4">We think that...</a>
                 </li>
             </ul>
         </li> */}
                    </ul>
                    {/* <div class="to-top">
         <a class="btn btn-primary btn-customized-3" href="#" role="button">
             <i class="fas fa-arrow-up"></i> Top
         </a>
     </div> */}
                    <div class="dark-light-buttons">
                        <button className="btn btn-sm btn-danger" onClick={()=> handleLogout()}>Log Out</button>
                        {/* <a class="btn btn-primary btn-customized-4 btn-customized-dark" href="#" role="button">Dark</a>
         <a class="btn btn-primary btn-customized-4 btn-customized-light" href="#" role="button">Light</a> */}
                    </div>
                </nav>
                <div class="overlay"></div>
                <div class="content">
                    <a class="btn btn-primary btn-customized open-menu" href="#" role="button">
                        <i class="fas fa-align-left"></i> <span>Menu</span>
                    </a>
                </div>

            </div>
        </>
    )
}
// export default Header
const mapStateToProps = (state) => {
    return {
        logged_in: state.data.logged_in,
        userToken: state.data.userToken,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedIn: (data) => dispatch(setLoggedIn(data)),
        setUserToken: (data) => dispatch(setUserToken(data)),
        setUserId: (data) => dispatch(setUserId(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);