import {  SET_LOGGED_IN, SET_USER_TOKEN,SET_USER_ID } from "./types";


export const setLoggedIn = (data) => {
    return {
        type: SET_LOGGED_IN,
        data: data
    }
}


export const setUserToken = (token) => {
    return {
        type: SET_USER_TOKEN,
        data: token
    }
}
export const setUserId = (id) => {
    return {
        type: SET_USER_ID,
        data: id
    }
}


