import React, { useEffect, useState } from "react";
import "./mainPage.scss"
import ScanImage from "../../assets/images/scanner.png"
import QrCodeReader from "react-qrcode-reader";
import axios from "../../axios";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { setLoggedIn,setUserToken } from "../../redux/Functions/actions";
const MainPage = (props) => {
    // alert(props.userID)
    const [isOpen, setIsOpen] = useState(false)
    const [catchData, setCatchData] = useState()
    const [userData, setUserData] = useState('')
    const [services, setServices] = useState([])
    const [error, setError] = useState(false)
    const [staffCredit , setStaffCredit] = useState()
    const getServices = (e) => {
        axios.get('/services/').then(
            data => {
                setServices(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }
    const getStaffCredit = () =>{
        axios.get(`/users/${props?.userID?._id}`).then(
            data => {
                setStaffCredit(data.data.credit)
            }
        ).catch(err => {
   console.log(err)
        })
    }
    const handleScan = async (scanData) => {
        // setLoadingScan(true);
        if (scanData !== null) {
            axios.get(`/users/qrcode/${scanData?.data}`).then(
                data => {
                    setUserData(data.data)
                    setIsOpen(false);
                    setCatchData(true);
                    setError(false)
                    getServices()
                    getStaffCredit()
                }
            ).catch(err => {
                console.log(err)
                setError(true)
                setCatchData(true);
                setIsOpen(false);
            })
            //thirret api
            console.log("vlera", scanData)
        } else {
            return null
        }
    };
    const handlePaymentProccess = (e) => {
        e.preventDefault()
        const servicePrice = e?.target?.serviceCredit?.value

        if (servicePrice > userData?.credit) {
            toast.error("Not enough credit")
        } else {
            const payValue = Number(userData?.credit) - Number(servicePrice)
            let body = {
                credit: Number(payValue)
            }
            axios.put(`/users/${userData?._id}`, body).then(
                data => {
                    toast.success('Payment completed')
              const staffCred =Number(staffCredit) + Number(e?.target?.serviceCredit?.value)

                    let staffPay = {
                        credit:Number(staffCred)
                    }
                    axios.put(`/users/${props?.userID?._id}`, staffPay).then(
                        data =>{
                        setCatchData(false);
                        toast.success("i shkoj puntorit")
                        }
                    ).catch(err =>{
                        toast.error("nuk i shkoj puntorit",err)
                    })
                }
            ).catch(err => {
                console.log(err)
                toast.error("something went wrong")
            })
       
        }
    }

    return (
        <>

            <div className="container py-5 h-100 mt-4">
                <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col col-lg-10 mb-4 mb-lg-0">
                        <div className="card mb-3" style={{ borderRadius: ".5rem;" }}>
                            <div className="row g-0">
                                <div className="col-md-4 gradient-custom text-center text-white pb-3"
                                    style={{ borderTopLeftRadius: ".5rem", borderBottomLeftRadius: ".5rem;" }}>
                                    {isOpen == true ?
                                        <QrCodeReader delay={120} width={300} height={396} onRead={handleScan} />
                                        :
                                        <img src={ScanImage} alt="Avatar" className="img-fluid my-5" style={{ width: "80px;", maxHeight: '300px' }} />
                                    }
                                    <h5 className="text-light">Skano Kartelen :</h5>
                                    <button className="btn btn-lg btn-warning" disabled={error != true && catchData == true} onClick={() => setIsOpen(!isOpen)}>
                                        <i className="fa-solid fa-camera"></i>
                                    </button>
                                </div>

                                {catchData == true ?
                                    error == true && catchData == true ?
                                        <div className="col-md-8">
                                            <h3 className="d-flex justify-content-center pt-5 text-danger">Kartela jo valide</h3>
                                        </div>
                                        :
                                        <>

                                            <div className="col-md-8">
                                                <div className="card-body p-4">
                                                    <form onSubmit={handlePaymentProccess}>
                                                        <h6>Informatat e Klientit</h6>
                                                        <hr className="mt-0 mb-4" />
                                                        <div className="row pt-1">
                                                            <div className="col-6 mb-3">
                                                                <h6>Klienti</h6>
                                                                <p className="text-muted">{userData?.userName}</p>
                                                            </div>
                                                            <div className="col-6 mb-3">
                                                                <h6>Piket</h6>
                                                                <p className="text-muted">{userData?.credit}</p>
                                                            </div>
                                                        </div>
                                                        <h6>Formulari i Sherbimit</h6>
                                                        <hr className="mt-0 mb-4" />
                                                        <div className="row pt-1">
                                                            <div className="col-6 mb-3">
                                                                <h6>Sherbimi</h6>
                                                                <select id="serviceCredit" name="serviceCredit" className="form-select">
                                                                    <option value="">--selekto sherbimin--</option>
                                                                    {services?.map(el => {
                                                                        return (

                                                                            <option value={el?.cost}>{el?.name} | Credit: {el?.cost}</option>
                                                                        )
                                                                    })}

                                                                </select>
                                                            </div>
                                                            {/* <div className="col-6 mb-3">
                                                                    <h6>Cmimi (Piket):</h6>
                                                                    <h6 className="text-dark pt-2"></h6>
                                                                </div> */}
                                                        </div>
                                                        <div className="d-flex justify-content-start gap-3">
                                                            <button type="submit" className="btn btn-lg btn-primary">Konfirmo</button>
                                                            <button type="button" className="btn btn-lg btn-danger" onClick={() => setCatchData(false)}>Anulo</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </>
                                    :
                                    <div className="col-md-8">
                                        <h3 className="d-flex justify-content-center pt-5">Skano per te caktuar sherbimin</h3>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
      logged_in: state.data.logged_in,
      userToken: state.data.userToken,
      userID: state.data.userID,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedIn: (data) => dispatch(setLoggedIn(data)),
      setUserToken: (data) => dispatch(setUserToken(data)),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
// export default MainPage
