import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import { toast } from "react-toastify";
import axios from "../../../axios";
const EditService = (props) => {

    const HandleEditService = (e) => {
        e.preventDefault()
        let body = {
           name:e.target.name.value,
           cost:e.target.cost.value
        }
        axios.put(`/services/${props?.data?._id}`,body).then(
            data =>{
                toast.success("Service added successfully")
                props?.handleModalClose()
                props?.getServices()
            }
        ).catch(err =>{
            toast.error("Something went wrong")
            console.log(err)
        })
    }

    return (
        <ModalPortal>
            <div className="modal-container">
            <div className="background"onClick={()=>props?.handleModalClose()}/>
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Perditeso Sherbim</h2>
                    <hr />
                    <form onSubmit={HandleEditService}>
                        <div class="form-group">
                            <label>Emri i Sherbimit</label>
                            <input defaultValue={props?.data?.name} required type="text" class="form-control" id="name" name="name" placeholder="e.x Larje Mbrenda" />
                        </div>
                        <div class="form-group">
                            <label>Cmimi (Piket)</label>
                            <input defaultValue={props?.data?.cost} required type="number" id="cost" name="cost" placeholder="e.x 30" class="form-control" />
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-secondary" onClick={()=> props?.handleModalClose()}>Anulo</button>
                        <button type="submit" class="btn btn-primary">Shto</button>
                        </div>
                    </form>

                </div>
            </div>
        </ModalPortal>
    )
}
export default EditService