import React, { useRef } from "react";
import ModalPortal from "../../../portal/ModalPortal";
import QRCode from "react-qr-code";

const QrCode = (props) => {
    const qrCodeRef = useRef(null);

    const downloadQRCode = () => {
        const qrCodeSvg = qrCodeRef.current;
        const svgClone = qrCodeSvg.cloneNode(true);

        svgClone.setAttribute("width", "100");
        svgClone.setAttribute("height", "100");

        const svgData = new XMLSerializer().serializeToString(svgClone);

        const canvas = document.createElement("canvas");
        canvas.width = 500;
        canvas.height = 500;
        const ctx = canvas.getContext("2d");

        const img = new Image();
        img.onload = () => {
            ctx.drawImage(img, 0, 0, 500, 500);

            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = "qr_code.png"; 
                link.click();
                URL.revokeObjectURL(url);
            }, "image/png");
        };

        img.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgData);
        props?.handleModalClose()
    };

    return (
        <ModalPortal>
            <div className="modal-container">
                <div className="background" onClick={() => props?.handleModalClose()} />
                <div className="modal-content">
                    <h4 className="d-flex mx-auto">Kartela: Amar Kastrati</h4>
                    <hr />
                    <div style={{ background: 'white', padding: '16px' }}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={"test"}
                            viewBox={`0 0 256 256`}
                            ref={qrCodeRef}
                        />
                    </div>
                    <div className="d-flex justify-content-center mt-3 gap-5">
                        <button type="button" className="btn btn-secondary" onClick={() => props?.handleModalClose()}>Mbyll</button>
                        <button type="button" className="btn btn-primary" onClick={()=>downloadQRCode()}>Download QR Code</button>
                    </div>
                </div>
            </div>
        </ModalPortal>
    )
}
export default QrCode;
