import { SET_LOGGED_IN, SET_USER_TOKEN,SET_USER_ID } from "./types";

const INITIAL_STATE = {
    logged_in: false,
    userToken: "",
    userID:""
};
const initialReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LOGGED_IN:
            return { ...state, logged_in: action.data }
        case SET_USER_TOKEN:
            return { ...state, userToken: action.data }
        case SET_USER_ID:
            return { ...state, userID: action.data }
        default: return state;

    }
};

export default initialReducer;