import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LogIn from './pages/Login/Login';
import Header from './components/header/header';
import Clients from './pages/Clients/clients';
import Staff from './pages/Staff/Staff';
import Subs from './pages/Subscription/subscription';
import MainPage from './pages/Main/mainPage';
import { connect } from 'react-redux';
import Services from "./pages/Services/services"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Profile from './pages/Profile/profile';
function App(props) {
  console.log('redux id', props?.userID)
  return (
    <>
      <ToastContainer />
      <Router>
        {props?.logged_in == false ?
          ""
          :
          <Header role={props?.userID?.role}/>
        }
        <Routes>
          {props?.logged_in == false ?
            <Route path="/*" element={<LogIn />} />
            :
            props?.logged_in == true && props?.userID?.role == 1 ?
              <>
                <Route path="/" element={<MainPage />} />
                <Route path="/client" element={<Clients />} />
                <Route path="/staff" element={<Staff />} />
                <Route path="/subscrition" element={<Subs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/profile" element={<Profile />} />
              </>
              :
              props?.logged_in == true && props?.userID?.role == 2 ?
                <>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/profile" element={<Profile />} />
                </>
                :
                ""
          }
        </Routes>
      </Router>
    </>
  );
}

// export default App;
const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
    userToken: state.data.userToken,
    userID: state.data.userID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    // setUserToken: (data) => dispatch(setUserToken(data)),
    // setUserId: (data) => dispatch(setUserId(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
