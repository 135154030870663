import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import axios from "../../../axios";
import { toast } from "react-toastify";

const Payment = (props) => {
    const PaymentConfrimed = ()=>{
        let body = {
            credit:0
        }
        axios.put(`/users/${props.data._id}`,body).then(
            response =>{
                toast.success("User payment completed!");
                props.handleModalClose()
                props.getUsers()
            }
        ).catch(err => {
            toast.error("Something Went Wrong")
            console.log(err)
        })
    }
    return (
        <ModalPortal>
            <div className="modal-container">
                <div className="background" onClick={() => props?.handleModalClose()} />
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Paga e stafit</h2>
                    <hr />
                        <ul class="list-group">
                            <li class="list-group-item bg-dark text-light">Staff: {props?.data?.userName}</li>
                            <li class="list-group-item">Credit: <strong>{props?.data?.credit}</strong></li>
                            <li class="list-group-item">Ne Euro: <strong>{props?.data?.credit / 2}</strong></li>
                        </ul>
                    <div className="d-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-secondary" onClick={() => props?.handleModalClose()}>Anulo</button>
                        <button type="submit" class="btn btn-success" onClick={()=> PaymentConfrimed()}>Konfirmo pagen</button>
                    </div>
                </div>
            </div>
        </ModalPortal>
    )
}
export default Payment