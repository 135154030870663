import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import axios from "../../../axios";
import { toast } from "react-toastify";
const DeleteStaff = (props) => {
console.log("props",props)
    const HandleDeleteStaff = () => {
        axios.delete('/users/'+props?.id).then(data=>{
            props?.handleModalClose()
            props?.getUsers()
            toast.success("User Deleted !")
        }).catch(err =>{
            toast.error("Something Went Wronf")
            console.log(err)
        })
      
    }

    return (
        <ModalPortal>
            <div className="modal-container">
            <div className="background" onClick={()=>props?.handleModalClose()}/>
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Fshij Stafin?</h2>
                    <hr />
                        <div className="d-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-secondary" onClick={()=> props?.handleModalClose()}>Anulo</button>
                        <button type="submit" class="btn btn-danger" onClick={()=>HandleDeleteStaff()}>Fshij</button>
                        </div>
                </div>
            </div>
        </ModalPortal>
    )
}
export default DeleteStaff