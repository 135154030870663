import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import axios from "../../../axios";
import { toast } from "react-toastify";
import sign from "jwt-encode";
const AddStaff = (props) => {

    const HandleAddStaff = (e) => {
        e.preventDefault()
        const secretKey = process.env.REACT_APP_SECRET_KEY
        let body = {
            userName: e.target.name.value,
            // qrCode: "coming soon",
            email:e.target.email.value,
            password:`${e.target.password.value}`,
            role :2,
            credit: 0,
            phone : e.target.phone.value
        }
        axios.post('/users/signup',body).then(
            response =>{
                toast.success("User Added Successfully");
                props.handleModalClose()
                props.getUsers()
                console.log(response.data)
            }
        ).catch(err => {
            toast.error(err.message)
            console.log(err)
        })
    }

    return (
        <ModalPortal>
            <div className="modal-container">
            <div className="background"onClick={()=>props?.handleModalClose()}/>
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Shto Staff</h2>
                    <hr />
                    <form onSubmit={HandleAddStaff}>
                        <div class="form-group">
                            <label>Emri i plote</label>
                            <input required type="text" class="form-control" id="name" name="name" placeholder="e.x belegu" />
                        </div>
                        <div class="form-group">
                        <label>Email</label>
                            <input required type="email" class="form-control" id="email" name="email" placeholder="e.x test@test.com" />
                        </div>
                        <div class="form-group">
                        <label>Password</label>
                            <input required type="password" class="form-control" id="password" name="password" placeholder="*******" />
                            <small className="text-danger size-sm">Password must contain at least one special character and at least one number</small>
                        </div>
                        <div class="form-group">
                        <label>Phone Nr</label>
                            <input required type="number" class="form-control" id="phone" name="phone" placeholder="ex. 49123123" />
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-secondary" onClick={()=> props?.handleModalClose()}>Anulo</button>
                        <button type="submit" class="btn btn-primary">Shto</button>
                        </div>
                    </form>

                </div>
            </div>
        </ModalPortal>
    )
}
export default AddStaff
