import React, { useEffect, useState } from "react";
import ModalPortal from "../../../portal/ModalPortal"
import "./addClientModal.scss"
import axios from "../../../axios";
import { toast } from "react-toastify";
import sign from "jwt-encode";
import QrCodeReader from "react-qrcode-reader";

const AddClient = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const [catchData, setCatchData] = useState(false)
    const [qrCode, setQrCode] = useState("")
    const [subData,setSubData] = useState([])

    const getSubscriptions = () => {
        axios.get("/subscriptions/").then(
            data => {
                setSubData(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }


    const handleScan = async (scanData) => {

        if (scanData !== null) {
            setIsOpen(false);
            setCatchData(true);
            setQrCode(scanData?.data)
            // console.log("vlera", scanData)
        } else {
            return null
        }
    };
    const HandleAddClient = (e) => {
        e.preventDefault()
        if (qrCode == "") {
            toast.info("Please scan the clients card")
        }
        else {

            const secretKey = process.env.REACT_APP_SECRET_KEY
            let passEnc = sign(`${e.target.password.value}`, secretKey)
            let body = {
                userName: e.target.name.value,
                qrCode: qrCode,
                email: e.target.email.value,
                password: passEnc,
                role: 3,
                credit: e.target.credit.value,
                phone: e.target.phone.value
            }
            axios.post('/users/signup', body).then(
                response => {
                    toast.success("Client Added Successfully");
                    props.handleModalClose()
                    props.getUsers()
                    console.log(response.data)
                }
            ).catch(err => {
                toast.error("Something Went Wrong")
                console.log(err)
            })
        }
    }
useEffect(()=>{
    getSubscriptions()
},[])
    return (
        <ModalPortal>
            <div className="modal-container">
                <div className="background" onClick={() => props?.handleModalClose()} />
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Shto Klient</h2>
                    <hr />
                    <form onSubmit={HandleAddClient}>
                        <div class="form-group">
                            <div className="d-flex justify-content-center">
                                {isOpen == true ?
                                    <QrCodeReader delay={120} width={200} height={200} onRead={handleScan} />
                                    :
                                    ""
                                }
                            </div>
                        </div>
                        <div class="form-group d-flex justify-content-center">
                            <button type="button" className="btn btn-lg btn-warning" disabled={catchData == true} onClick={() => setIsOpen(!isOpen)}>
                                <i className="fa-solid fa-camera"></i>
                            </button>
                        </div>
                        <div class="form-group">
                            <label>Emri i plote</label>
                            <input required type="text" class="form-control" id="name" name="name" placeholder="e.x belegu" />
                        </div>
                        <div class="form-group">
                            <label>Phone nr</label>
                            <input required type="number" class="form-control" id="phone" name="phone" placeholder="e.x 49123123" />
                        </div>
                      
                        <div class="form-group">
                            <label>Email</label>
                            <input required type="email" id="email" name="email" placeholder="e.x example@mail.com" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input required type="password" id="password" name="password" placeholder="*******" class="form-control" />
                            <small className="text-danger size-sm">Password must contain at least one special character and at least one number</small>
                        </div>
                        <div class="form-group">
                            <label>Kartela</label>
                            <select id="credit" name="credit" required className="form-select">
                                <option hidden>--select card--</option>
                            {subData.map(el =>{
                                return(
                                    <>
                                        <option value={el.Credit}>{el.Name}</option>
                                    </>
                                )
                            })}
                            </select>
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                            <button type="button" class="btn btn-secondary" onClick={() => props?.handleModalClose()}>Anulo</button>
                            <button type="submit" class="btn btn-primary">Shto</button>
                        </div>
                    </form>

                </div>
            </div>
        </ModalPortal>
    )
}
export default AddClient
