import React from "react";
import jwtDecode from "jwt-decode";
import sign from "jwt-encode"
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { setLoggedIn, setUserToken,setUserId } from "../../redux/Functions/actions";
import axios from "../../axios";
import { toast } from "react-toastify";
const LogIn = (props) =>{

  const handleLogin =(e)=>{
    e.preventDefault()
  const secretKey = process.env.REACT_APP_SECRET_KEY
  let passEnc = sign( `${e.target.password.value}`, secretKey)
    let body = {
      email : e.target.email.value,
      password : `${e.target.password.value}`
    }
    axios.post('/users/login',body).then(
      data =>{
        window.localStorage.setItem('token',data.data.token)
        props?.setLoggedIn(true)
        props.setUserToken(data.data.token)
        props.setUserId(data.data.user)
        toast.success("Logged in !")
        window.location.href = "/"
      }
    ).catch(err =>{
      console.log(err)
      toast.error("Something went wrong!")
    })
    console.log("Log IN :" , body , props?.logged_in , props?.userToken)
  }


    return(
        <>
        <section className="vh-100" style={{backgroundColor: "lightgray"}}>
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col col-xl-10">
        <div className="card" style={{borderRadius:"1rem"}}>
          <div className="row g-0">
            <div className="col-md-6 col-lg-5 d-none d-md-block">
              <img src="https://static.vecteezy.com/system/resources/previews/007/688/848/original/car-wash-logo-free-vector.jpg"
                alt="login form" className="img-fluid" style={{borderRadius: "1rem 0 0 1rem"}} />
            </div>
            <div className="col-md-6 col-lg-7 d-flex align-items-center">
              <div className="card-body p-4 p-lg-5 text-black">

                <form onSubmit={handleLogin}>

                  <div className="d-flex align-items-center mb-3 pb-1">
                    <i className="fas fa-cubes fa-2x me-3" style={{color: "#0089d1"}}></i>
                    <span className="h1 fw-bold mb-0">Logo</span>
                  </div>

                  <h5 className="fw-normal mb-3 pb-3" style={{letterSpacing: "1px"}}>Sign into your account</h5>

                  <div className="form-outline mb-4">
                    <label className="form-label" >Email address</label>
                    <input type="email" id="email" name="email" required className="form-control form-control-lg" />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label">Password</label>
                    <input type="password" id="password" name="password" required className="form-control form-control-lg" />
                  </div>

                  <div className="pt-1 mb-4">
                    <button className="btn btn-dark btn-lg btn-block" type="submit">Login</button>
                  </div>
                  <hr />
                  {/* powered by: <strong>Deri tja gjejm</strong> */}
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
        </>
    )
}
// export default LogIn
const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
    userToken: state.data.userToken,
    userID: state.data.userID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedIn: (data) => dispatch(setLoggedIn(data)),
    setUserToken: (data) => dispatch(setUserToken(data)),
    setUserId: (data) => dispatch(setUserId(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
