import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import axios from "../../../axios";
import { toast } from "react-toastify";
const EditClient = (props) => {

    const HandleEditClient = (e) => {
        e.preventDefault()
        let body = {
            userName: e?.target?.name?.value,
            phone_nr: e?.target?.phone?.value,
            credit: Number(props.dataToModal?.credit) + Number(e?.target.credit?.value),
            email : e?.target?.email?.value
        }
        axios.put(`/users/${props.dataToModal?._id}`,body).then(
            response =>{
                toast.success("Client Edited Successfully");
                props.handleModalClose()
                props.getUsers()
            }
        ).catch(err => {
            toast.error("Something Went Wrong")
            console.log(err)
        })
    }

    return (
        <ModalPortal>
            <div className="modal-container">
            <div className="background"onClick={()=>props?.handleModalClose()}/>
                <div className="modal-content">
                    <h2>Perditeso Klient</h2>
                    <hr />
                    <form onSubmit={HandleEditClient}>
                        <div class="form-group">
                            <label>Emri i plote</label>
                            <input required defaultValue={props?.dataToModal?.userName} type="text" class="form-control" id="name" name="name" placeholder="e.x belegu" />
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input required defaultValue={props?.dataToModal?.email} type="email" id="email" name="email" placeholder="e.x 49111222" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Numri i telefonit</label>
                            <input required defaultValue={props?.dataToModal?.phone} type="number" id="phone" name="phone" placeholder="e.x 49111222" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Kartela</label>
                            <select id="credit" name="credit" required className="form-select">
                                <option hidden>--select card--</option>
                                <option value={50}>Silver</option>
                                <option value={100}>Gold</option>
                                <option value={300}>VIP</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Credit: {props?.dataToModal?.credit}</label>
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-secondary" onClick={()=> props?.handleModalClose()}>Anulo</button>
                        <button type="submit" class="btn btn-primary">Perditeso</button>
                        </div>
                    </form>

                </div>
            </div>
        </ModalPortal>
    )
}
export default EditClient