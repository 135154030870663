import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import { toast } from "react-toastify";
import axios from "../../../axios";
const DeleteService = (props) => {

    const HandleDeleteService = () => {
        axios.delete(`/services/${props?.id}`).then(
            data =>{
                toast.success("Service deleted successfully")
                props?.handleModalClose()
                props?.getServices()
            }
        ).catch(err =>{
            toast.error("Something went wrong")
            console.log(err)
        })
    }

    return (
        <ModalPortal>
            <div className="modal-container">
            <div className="background" onClick={()=>props?.handleModalClose()}/>
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Fshij sherbimin?</h2>
                    <hr />
                        <div className="d-flex justify-content-center gap-5">
                        <button type="button" class="btn btn-secondary" onClick={()=> props?.handleModalClose()}>Anulo</button>
                        <button type="submit" class="btn btn-danger" onClick={()=>HandleDeleteService()}>Fshij</button>
                        </div>
                </div>
            </div>
        </ModalPortal>
    )
}
export default DeleteService