import React, { useEffect, useState } from "react";
import "./profile.scss"
import axios from "../../axios";
import { connect } from "react-redux";
const Profile = (props) => {
    const [userData, setUserData] = useState()
    const getSingleUser = () => {
        axios.get(`/users/${props?.userID?._id}`).then(
            data => {
                setUserData(data.data)
            }
        ).catch(err => {
            console.log(err)
        })
    }
    console.log('userData', userData)
    useEffect(() => {
        getSingleUser()
    }, [])
    return (
        <>
            <div className="d-flex justify-content-center mt-4 container">
                <div class="main-body col">
                    <div class="cards">
                        <div class="col-md-4 mb-3">
                            <div class="profile-card">
                                <div class="card-body">
                                    <div class="d-flex flex-column align-items-center text-center">
                                        <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="Admin" class="rounded-circle" width="150" />
                                        <div class="mt-3">
                                            <h4>{userData?.userName}</h4>
                                            <p class="text-secondary mb-1">Pozita: {userData?.role}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="profile-card mb-3">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Full Name</h6>
                                        </div>
                                        <div class="col-sm-9 text-light">
                                            {userData?.userName}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Email</h6>
                                        </div>
                                        <div class="col-sm-9 text-light">
                                            {userData?.email}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Phone</h6>
                                        </div>
                                        <div class="col-sm-9 text-light">
                                            {userData?.phone}
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <h6 class="mb-0">Credit</h6>
                                        </div>
                                        <div class="col-sm-9 text-light">
                                            {userData?.credit}
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="container">
                <center className="mt-5 ">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="single_advisor_profile wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp" }}>
                            <div class="advisor_thumb">
                                <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                                <div class="social-info">
                                    <a href="#"><i class="fa fa-facebook"></i></a><a href="#"><i class="fa fa-twitter"></i></a><a href="#"><i class="fa fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div class="single_advisor_details_info text-light">
                                <h6>Samantha Sarah</h6>
                                <p class="designation">Founder &amp; CEO</p>
                            </div>
                        </div>
                    </div>
                </center>
            </div> */}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        //   logged_in: state.data.logged_in,
        //   userToken: state.data.userToken,
        userID: state.data.userID,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //   setLoggedIn: (data) => dispatch(setLoggedIn(data)),
        //   setUserToken: (data) => dispatch(setUserToken(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
// export default Profile