import React from "react";
import ModalPortal from "../../../portal/ModalPortal"
import axios from "../../../axios";
import { toast } from "react-toastify";
const AddSubscription = (props) => {

    const HandleAddSubscription = (e) => {
        e.preventDefault()
        if (e.target.credit.value == 0 || e.target.price.value == 0) {
            toast.info("The numbers should be above 0")
        }
        else {

            let body = {
                Name: e.target.name.value,
                Price: Number(e.target.price.value),
                Credit: Number(e.target.credit.value)
            }
            axios.post('/subscriptions/', body).then(
                data => {
                    toast.success("Card added successfully !")
                    props.handleModalClose()
                    props.getSubscriptions()
                }
            ).catch(err => {
                toast.error("Something went Wrong")
                console.log(err)
            })
        }
    }

    return (
        <ModalPortal>
            <div className="modal-container">
                <div className="background" onClick={() => props?.handleModalClose()} />
                <div className="modal-content">
                    {/* Your modal content goes here */}
                    <h2>Shto Kartel</h2>
                    <hr />
                    <form onSubmit={HandleAddSubscription}>
                        <div class="form-group">
                            <label>Emri i Karteles</label>
                            <input required type="text" class="form-control" id="name" name="name" placeholder="e.x Larje Mbrenda" />
                        </div>
                        <div class="form-group">
                            <label>Krediti (Piket)</label>
                            <input required type="number" id="credit" name="credit" placeholder="e.x 30" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Cmimi (euro)</label>
                            <input required type="number" id="price" name="price" placeholder="e.x 30" class="form-control" />
                        </div>
                        <div className="d-flex justify-content-center gap-5">
                            <button type="button" class="btn btn-secondary" onClick={() => props?.handleModalClose()}>Anulo</button>
                            <button type="submit" class="btn btn-primary">Shto</button>
                        </div>
                    </form>

                </div>
            </div>
        </ModalPortal>
    )
}
export default AddSubscription